<template>
  <div class="wrap">
    <div class="btn-box">
      <div class="download" @click="download">下载客户端</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    download() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; // Android
      if (isAndroid) {
        location.href =
          "http://resources.sxdjcq.com/apk/member/member_cmOfficial_release.apk";
          // "https://webcdn.m.qq.com/webapp/homepage/index.html#/appDetail?apkName=com.sxyj.app.member&info=1563BAF0869002CFF044B5F4B7C92E95";
      } else {
        location.href = "https://apps.apple.com/cn/app/id1628054432";
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.wrap {
  position: relative;
  background: url("../../assets/img/bg.png") center center no-repeat;
  /* background-size: 100% 100%; */
  background-size: cover;
  height: 100vh;
}

.wrap .btn-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.download {
  margin: 20px auto;
  width: 90%;
  padding: 16px 0;
  text-align: center;
  font-size: 18px;
  background-color: #32a2d3;
  border-radius: 30px;
  color: #ffffff;
}
</style>
